(() => {
  const wrappers = document.getElementsByClassName('jicoo-widget') || [];

  for (let i = 0; i < wrappers.length; i++) {
    const wrapper = wrappers[i];
    if (!wrapper) continue;
    const src = wrapper.getAttribute('data-url');
    if (!src) return;

    const iframe = document.createElement('iframe');
    const iframeName: any = `jicoo-widget-iframe-${i}`;
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('name', iframeName);
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('src', src);
    wrapper.appendChild(iframe);

    window.addEventListener('message', (e: MessageEvent) => {
      if (!e.isTrusted) {
        return;
      }
      const iframe = document.getElementsByName(iframeName)[0];
      const data = e.data;
      switch (data.name) {
        case 'redirectUrl': {
          const currentUrl: string = data.value;
          try {
            if (currentUrl !== src) {
              const url = new URL(currentUrl);
              const original = new URL(src);
              const eventTypePathPattern = /^\/t\/[^/]+\/e\/[^/]+$/;
              const isRedirectToEventTypePage = eventTypePathPattern.test(
                url.pathname,
              );
              if (
                url.hostname !== original.hostname ||
                isRedirectToEventTypePage
              ) {
                iframe.setAttribute('style', 'visibility:hidden;');
                window.location.href = currentUrl;
              }
            }
          } catch (error) {
            console.debug(error);
          }
          break;
        }
        case 'windowHeight': {
          const height: string = data.value;
          iframe.setAttribute('height', `${height}`);
          wrapper.setAttribute(
            'style',
            wrapper.getAttribute('style') + `height:${height}px;`,
          );
          break;
        }
        case 'scrollWidgetTop': {
          const widgetFrame = document.getElementsByClassName('jicoo-widget');
          if (widgetFrame.length > 0) {
            widgetFrame[0].scrollIntoView();
          }
          break;
        }
        default:
          break;
      }
    });
  }
})();
